var hidden, visibilityChange,runscripts;
if (typeof document.hidden !== "undefined") { // Opera 12.10 and Firefox 18 and later support
    hidden = "hidden";
    visibilityChange = "visibilitychange";
} else if (typeof document.msHidden !== "undefined") {
    hidden = "msHidden";
    visibilityChange = "msvisibilitychange";
} else if (typeof document.webkitHidden !== "undefined") {
    hidden = "webkitHidden";
    visibilityChange = "webkitvisibilitychange";
}


function isWindowVisible(){
    var windowIsVisible=true;
    if(typeof document[hidden] === "undefined"){

    }else  if (document.hidden) {
        windowIsVisible=false;
    }

    return windowIsVisible;
}