// jQuery(document).on('keyup', '#amount_for_all_bets', function () {
//     const amount = jQuery(this).val();
//     amount !== "" && amount !== "0" ? all_bets_amount = amount : all_bets_amount = "";
//     setAmountToAllBets(amount);
// });
//
// jQuery(document).on('change', '.open-spot-picker', function (event) {
//     const target = jQuery(event.target);
//     updateOpenSpots(_current_tab, target.val());
// });
//
// jQuery(document).on('change', '.betslip__select-sub-type', function (event) {
//     const target = jQuery(event.target);
//     updateSubType(_current_tab, target.val());
// });
//
// jQuery(document).on('change', '.betslip__select-if-bet-type', function (event) {
//     const target = jQuery(event.target);
//     updateIfBetType(target.val());
// });
//
// jQuery(document).on('change', '[data-selection-identifier-buy-points]', function (event) {
//     const target = jQuery(event.target);
//     const buy_pts_id = target.data('id');
//     const selected = target.find('[value="' + target.val() + '"]');
//
//     updatePointsSelection(buy_pts_id, selected.data('spread'), selected.data('odds'), selected.data('points'));
//     triggerRiskWinCalculation();
// });
//
// jQuery(document).on('change', '.teaser-select', function (event) {
//     const selectedTeaserOption = jQuery(event.target).find('option:selected');
//     const is_secondary = jQuery(event.target).hasClass('second');
//
//     changeTeaserVisualPts(
//         is_secondary,
//         selectedTeaserOption.data('nfl_side'),
//         selectedTeaserOption.data('nba_side'),
//         selectedTeaserOption.data('cfb_side'),
//         selectedTeaserOption.data('cbb_side')
//     );
//
//     triggerRiskWinCalculation();
// });
//
// jQuery(document).on('keyup', '.risk_input', function (event) {
//     const target = jQuery(event.target);
//     let max_payout_odd = 0;
//     let max_payout = 0;
//
//     if(target.data('max-payout') != null) max_payout = target.data('max-payout');
//     if(target.data('max-payout-odd') != null) max_payout_odd = target.data('max-payout-odd');
//
//     getWin(target.data('type'), target.data('id'), target.data('odds'), max_payout, max_payout_odd, 0);
// });
//
// jQuery(document).on('keyup', '.win_input', function (event) {
//     const target = jQuery(event.target);
//     getRisk(target.data('type'), target.data('id'), target.data('odds'), target.data('max-payout'), 0);
// });
//
// jQuery(document).on('click', '.bet-type-tab', function (event) {
//     const target = jQuery(event.target);
//     _current_tab = target.data('type');
//     setCurrenTabId(target.data('type'));
//
//     createBetToCallBetSlip(_current_tab, _current_tab_id, 0, _selectionsToCallBetSlip.length);
//     showSlip();
// });
//
// jQuery(document).on('change', '.pitcher-picker', function () {
//     const target = jQuery(this);
//     updatePitcherSelection(target.val(), target.data('selection-id'));
// });
//
// jQuery(document).on('click' , '.clear_bet_slip', function () {
//     clearBets();
// });
//
// jQuery(document).on('click' , '.myb-main-container__close-icon', function (event) {
//     const target = jQuery(event.target);
//     const bet_id = target.attr('id');
//
//     removeSelection(bet_id);
//
//     if(_current_tab != 'straight')
//         setTimeout(() => { jQuery('.myb-betslip__bet-input').trigger('keyup'); }, 1000);
//
// });

let _executed = false;

jQuery(document).on('click', '.lines-odds', function (event) {
    let eventDetail = event.detail === undefined ? 'true' : event.detail <= 1;

    if (eventDetail) {
        let target = jQuery(event.target);

        // const line = target.children()[0].attributes.classname;
        //
        // if (line !== undefined){
        //     return false;
        // }

        if(!target.data('outcomeid')){
            target = jQuery(event.target).parent();
        }

        if(!target.data('outcomeid')){
            return false;
        }

        //this function adds the active class the button
        jQuery(this).toggleClass('active');

        var data = target.data();

        if (Number(document.querySelector('#current-league').getAttribute('data-b-id')) !== 85) {
            if (!_executed) {

                jQuery.getScript("/wp-content/plugins/wp_plugin_sportsbook_guest/resources/js/vue.main.min.js")
                    .done(function() {
                        _executed = true;
                        setTimeout(()=>{
                            var evt = new CustomEvent("add2BetSlip", {'detail': data});
                            window.dispatchEvent(evt);
                        }, 1000)
                    })
                    .fail(function() {
                        console.log('fail to load script');
                    });
            }else{
                var evt = new CustomEvent("add2BetSlip", {'detail': data});
                window.dispatchEvent(evt);
            }
        } else {
            var evt = new CustomEvent("add2BetSlip", {'detail': data});
            window.dispatchEvent(evt);
        }

        // add2Slip(target);
    }
});
