//Global Structures
const version               = '5.0.0';
let _debug                  = false;
let _selections             = JSON.parse('[]');
let _selectionsToCallBetSlip = JSON.parse('[]');
let _bets                   = JSON.parse('[]');
let _betsToCallBetSlip      = JSON.parse('[]');
let _sport_ids              = JSON.parse('[]');
let _current_tab            = 'straight';  //default tab
const _lineformat           = jQuery('body').data('line_style'); // e = US || d = decimal  ||  f = fraction
let all_bets_amount         = "";
let newVersion              = true;
let a_vs_b                  = [];
const betSlip               = jQuery('#betSlip');
const bet_slip_main_container  = jQuery('#betslip_main_container');
let _current_tab_id       = 0;

if (document.querySelector('#current-league') != null){
    const book                  = document.querySelector('#current-league').getAttribute('b-id');
}


/**
 * INIT BET SLIP
 * Initialization.
 ========================================================================== */
function init(v) {
    console.log('Starting Betslip: ' + v);
    _selections = readSelections();
    _bets = readBets();

    if (readCurrentTab() != null && readCurrentTab() != "")
        _current_tab = readCurrentTab();

    if(_selections.length > 0){
        a_vs_b = JSON.parse(getItemFromLocalStorage('vs_data'));
    }

    console.log('Loaded ' + _bets.length + ' bets with ' + _selections.length + ' selections ');

    // showSlip();
    highLightButton();
    collapseBetSlipMobile();
}

function setAmountToAllBets(amount){
    jQuery('#straight .risk_input').each( (index, self) => {
        const bet = jQuery(self);
        bet.val(amount);
        bet.trigger( "keyup" );
    });
}

function highLightButton() {
    _selections.forEach((selection) => {
        const button = jQuery(`#${selection.id}`);
        if(button.length) button.addClass('active');
    });
}

function debug(data) {
    if (_debug) console.log(data)
}

//Save Cookies
function saveCookie() {
    writeBets(_bets, _betsToCallBetSlip);
    writeSelections(_selections,_sport_ids,_selectionsToCallBetSlip);
    createCookie('_current_tab', _current_tab, 1);
    createCookie('_current_tab_id', _current_tab_id, 1);

}

function changeHTML(selector, html) {
    if (jQuery(selector).length) jQuery(selector).html(html);
}

//Clear Bets Function
function hideOdds(){
    const a     = jQuery('.bet-slip__header__s');
    const odds  = jQuery('.bet-slip__header__odds');
    const b     = jQuery('.badge-counter');

    a.addClass('d-none');
    b.addClass('d-none');
    odds.html();
}

function clearBets() {
    _selections     = JSON.parse('[]');
    _bets           = JSON.parse('[]');
    _current_tab    = 'straight';

    bet_slip_main_container.html(`
        <div class="empty-betslip p-3">
<!--                <p class="text-center">Add your selections to the Bet Slip <br> and place your wager to start winning</p>-->
            </div>
    `);

    jQuery('.lines-odds').removeClass('active');

    if(newVersion){
        if(book != 85 ){
            betSlip.addClass('d-none');
        }else{
            if(checkSize()){
                betSlip.addClass('d-none');
            }
        }

        a_vs_b = [];
        deleteFromLocalStorage("vs_data");
        fixBetSlipMobile(jQuery('body') , jQuery('.contact-help-chat'));
        hideOdds();
    }

    if(checkSize()){
        jQuery('body').removeAttr("style");
    }

    saveCookie();
}

function getStraightUniqueBet(id) {
    for ( let ii = 0; ii < _selections.length; ii++) {
        if ((_selections[ii].id) == id)
            return _selections[ii];
    }
}

function buildBets(send_zeros) {
    let bets = [] , temp_bet = {} , filter = [];

    if (_current_tab == 'straight') {
        filter = ['straight', 'parlay', 'teaser'];
    } else if (_current_tab == 'if_win') {
        filter = ['if_win', 'if_win_or_tie'];
    } else {
        filter = [_current_tab];
    }

    // debug({"building bets": _bets});

    for (let i = 0; i < _bets.length; i++) {
        if (_bets[i].input_risk_amount > 0 || send_zeros) {
            if (filter.indexOf(_bets[i].type) > -1) {
                if (_bets[i].type == 'straight') {
                    temp_bet = _bets[i];
                    temp_bet.selections = [getStraightUniqueBet(temp_bet.betid)];
                    bets.push(temp_bet);
                } else {
                    temp_bet = _bets[i];
                    temp_bet.selections = _selections;
                    bets.push(temp_bet);
                }
            }
        }
    }

    // debug({"built bets": _bets});

    return bets;
}

function changeTeaserVisualPts(is_secondary, nflpts, nbapts, cfbpts, cbbpts) {
    let pts = 0;
    for (let i = 0; i < _selections.length; i++) {
        if (_selections[i].dgs_sport_id == 'NFL') pts = nflpts;
        if (_selections[i].dgs_sport_id == 'NBA') pts = nbapts;
        if (_selections[i].dgs_sport_id == 'CFB') pts = cfbpts;
        if (_selections[i].dgs_sport_id == 'CBB') pts = cbbpts;

        let totalPts = parseFloat(_selections[i].spread) + parseFloat(pts);

        if (_selections[i].line != 'to') {
            if (totalPts == 0) {
                totalPts = 'PK';
            } else {
                if (totalPts > 0) {
                    totalPts = '+' + totalPts;
                }
            }
        } else {
            if (totalPts > 0) {
                totalPts = 'U ' + totalPts;
            }
            if (totalPts < 0) {
                totalPts = 'O ' + totalPts.toString().replace('-', '');
            }
        }

        if (is_secondary) {
            changeHTML('#teaser_points_' + _selections[i].id, totalPts);
        } else {
            changeHTML('#teaser_points2_' + _selections[i].id, totalPts);
        }
    }
}

function changeInputAmount(inputId, amount) {
    if (jQuery(inputId).length)
        jQuery(inputId).attr('value', (amount == 0) ? '' : amount);
}

function changeWinAmount(winSpan,amount){
    if(jQuery(winSpan).length )
        jQuery(winSpan).html((amount == 0)  ? '0.00' : amount);
}

function numericType() {
    const isMobile = {
        Android: function () {
            return navigator.userAgent.match(/Android/i);
        },
        BlackBerry: function () {
            return navigator.userAgent.match(/BlackBerry/i);
        },
        iOS: function () {
            return navigator.userAgent.match(/iPhone|iPad|iPod/i);
        },
        Opera: function () {
            return navigator.userAgent.match(/Opera Mini/i);
        },
        Windows: function () {
            return navigator.userAgent.match(/IEMobile/i);
        },
        any: function () {
            return (isMobile.Android() || isMobile.BlackBerry() || isMobile.iOS() || isMobile.Opera() || isMobile.Windows());
        }
    };
    if (isMobile.any()) {
        jQuery('.risk_input').attr('type', 'number');
        jQuery('.win_input').attr('type', 'number');
        jQuery('#amount_for_all_bets').attr('type', 'number');
    }
}

function setNumeric() {
    jQuery(".risk_input").numeric({
        allowMinus: false,
        allowThouSep: false
    });
    jQuery('.win_input').numeric({
        allowMinus: false,
        allowThouSep: false
    });
    jQuery('.betslip__set-allinput').numeric({
        allowMinus: false,
        allowThouSep: false
    });
    jQuery('#amount_for_all_bets').numeric({
        allowMinus: false,
        allowThouSep: false
    });
}


function triggerChangeInputAmount(){
    const setAllInput = jQuery('.betslip__set-allInput');

    setAllInput.val(all_bets_amount);
    setAllInput.trigger('keyup');

    for (let ix = 0; ix < _bets.length; ix++) {
        if (_bets[ix].betid != '') { //straight
            changeInputAmount("#risk_" + _bets[ix].betid, _bets[ix].input_risk_amount);

            if(!newVersion)
                changeInputAmount("#win_" + _bets[ix].betid, _bets[ix].input_win_amount);
            else
                changeWinAmount("#win_" + _bets[ix].betid, _bets[ix].input_win_amount);

            if (_bets[ix].points > 0) {
                jQuery("#buyPoints_" + _bets[ix].betid).val(_bets[ix].points).change();
            }
        } else {
            changeInputAmount("#risk_" + _bets[ix].type + "_1", _bets[ix].input_risk_amount);
            changeInputAmount("#risk_" + _bets[ix].type + "_2", _bets[ix].input_risk_amount);

            if(!newVersion){
                changeInputAmount("#win_" + _bets[ix].type + "_1", _bets[ix].input_win_amount);
                changeInputAmount("#win_" + _bets[ix].type + "_2", _bets[ix].input_win_amount);
            }else{
                changeWinAmount("#win_" + _bets[ix].type + "_1", _bets[ix].input_win_amount);
                changeWinAmount("#win_" + _bets[ix].type + "_2", _bets[ix].input_win_amount);
            }
        }
    }
}

function updateOpenSpotsCallback(){
    let _openSpots = 0;

    for (let i = 0; i < _bets.length; i++) {
        if (_bets[i].type == 'parlay') {
            if (_bets[i].open_spots > 0)
                _openSpots = _bets[i].open_spots;
        }
    }

    if (_openSpots > 0)
        jQuery('#openSP').val(_openSpots).change();
}

function setInputRestrictions() {
    highlighteCurrentTab();
    numericType();
    setNumeric();
    triggerChangeInputAmount();
    updateOpenSpotsCallback();
    totalBets('');
    jQuery('.teaser-select').trigger('change');
}

function capitalize(str) {
    let splitStr = str.toLowerCase().split(' ');

    for (let i = 0; i < splitStr.length; i++) {
        splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
    }

    return splitStr.join(' ');
}

function viewOddsSum() {
    let typeBetContainer        = '';
    const betSlipOddsSum        =  jQuery('.bet-slip__header__s');
    const betSlipOddsSumTotal   =  jQuery('.bet-slip__header__odds');
    const betSlipOddsTotalOnBet =  jQuery('.t-odds');
    let oddsSum                 =  0;

    if(_current_tab == 'straight' )
        typeBetContainer = '.quick_parlay_wager_collapse';

    if(_current_tab == 'parlay')
        typeBetContainer = '.parlay_wager_collapse';
    // else{
    //     typeBetContainer = `#${_current_tab}_wager_collapse:visible`;
    // }

    if(jQuery(typeBetContainer).length){
        const source = jQuery('.bet__odds:first:visible');
        const odd = source.data('odd');
        const maxPayOdd = source.data('max-payout-odd');

        oddsSum  = parlayGetOdd(_selections, odd, maxPayOdd);
        betSlipOddsSum.removeClass('d-none');
        betSlipOddsSumTotal.html(oddToMoneyline(oddsSum));
        betSlipOddsTotalOnBet.html(oddToMoneyline(oddsSum));
    }else{
        betSlipOddsSum.addClass('d-none');
    }
}

function buildVsDesc() {
    jQuery.each( a_vs_b, ( key, value ) => {
        const t = jQuery(`.bet-content__selection__match-info__vs[data-i="${value.id}"]`);
        let desc = "";

        if(value.teams.team_2 == "" || value.teams.team_2 == undefined ){
            desc = `${capitalize(value.teams.team_1)}`;
        }else{
            desc = `${capitalize(value.teams.team_1)} vs ${capitalize(value.teams.team_2)}`
        }

        t.html(desc);
    });
}

function preventBodyScrollOnBetSlip() {
    jQuery('div.bet-slip-bets-body').on( 'mousewheel DOMMouseScroll', function (e) {
        const e0 = e.originalEvent;
        const delta = e0.wheelDelta || -e0.detail;

        this.scrollTop += ( delta < 0 ? 1 : -1 ) * 30;

        e.preventDefault();
    });
}

function showSlip() {
    const selections = {"listSelection" : _sport_ids, "fillOpenSpots": "false", "betId" : null};

    if (selections) {
        const dataSent = JSON.stringify(selections);
        const url = '/wp-content/plugins/wp_plugin_sportsbook_guest/view_betslip.php';

            sendDataBetSlip(dataSent, url, _current_tab).then(content => {
                console.log(content);
                // showSlipCallBack(content);
            },(jqXhr, textStatus, errorThrown) => {
                handlerBetSlipError(jqXhr, textStatus, errorThrown);
            });

    // const bets = buildBets(true);
    // if (bets.length > 0) {
    //     const dataSent = JSON.stringify(bets);
    //     const url = '/wp-content/plugins/wp_plugin_sportsbook_guest/view_betslip.php';
    //
    //     sendDataBetSlip(dataSent, url, _current_tab).then(content => {
    //         showSlipCallBack(content);
    //     },(jqXhr, textStatus, errorThrown) => {
    //         handlerBetSlipError(jqXhr, textStatus, errorThrown);
    //     });
    //
    //     countSelections();
    // }
    // else {
    //     // console.error('Tried to send empty bets');
    //     // clearBets();
    }
}

function showSlipCallBack(content) {
    const targetContainer = jQuery('#betslip_main_container');

    if(targetContainer.length > 0)
        targetContainer.html(content);

    setInputRestrictions();

    if (newVersion) {
        if(betSlip.hasClass('d-none'))
            betSlip.removeClass('d-none');

        viewOddsSum();
        buildVsDesc();
        preventBodyScrollOnBetSlip();
        fixBetSlipOnInit();
    }
}

function handlerBetSlipError(jqXhr, textStatus, errorThrown) {
    console.error(jqXhr);
    console.error(textStatus);
    console.error(errorThrown);
    clearBets();
}

function sendDataBetSlip(bets, uri,  tab_current) {
    // if (bets.length < 1) {
    //     return;
    // }

    // let selection_bet_slip = "";
    //
    // if (_selections.length)
    //     selection_bet_slip = JSON.stringify(_selections);

    // return new Promise( (resolve, reject) => {
        jQuery.ajax({
            url: uri,
            type: 'post',
            data: {
                'betId': null,
                'fillOpenSpots': "false",
                'listSelection': bets
            },
            success: (content) => {
                // resolve(content);
            },
            error: (jqXhr, textStatus, errorThrown) => {
                // reject(jqXhr, textStatus, errorThrown);
            }
        });
    // });
}

function validate_request(name_applications, name_request, request) {
    if ((request['status'] == 'false' || request['status'] == false) && typeof request['status'] !== "undefined") {
        console.log('------------ ERROR ---------------');
        console.log('NAME APPLICATION: ' + name_applications);
        console.log('REQUEST: ' + name_request);
        console.log('TYPE ERROR: ');
        console.log(request);
        console.log('------------ END ERROR------------');
    }
}

// function containsObject(object, key) {
//     for (let i = 0; i < object.length; i++) {
//         if ((object[i].id) == key)
//             return true;
//     }
//
//     return false;
// }

function removeObj(obj , id) {
    for (let i = 0; i < obj.length; i++) {
        if ((obj[i].id) == id)
            obj.splice(i, 1);
    }
}

function saveOnLocalStorage(name, obj){
    if (typeof(Storage) !== "undefined") localStorage.setItem(name, obj);
    else console.log('Sorry! No Web Storage support..');
}

function deleteFromLocalStorage(name){
    if (typeof(Storage) !== "undefined") localStorage.removeItem(name);
    else console.log('Sorry! No Web Storage support..');
}

function getItemFromLocalStorage(name){
    return localStorage.getItem(name);
}


function getTeamsVs(id, team, team_vs) {
    if(!containsObject(a_vs_b, id)){
        const newVal = {
            'id': id,
            'teams': {
                'team_1': team,
                'team_2': team_vs
            }
        };
        a_vs_b.push(newVal);
    }else{
        removeObj(a_vs_b, id);
    }

    saveOnLocalStorage('vs_data', JSON.stringify(this.a_vs_b));
}

/*CREATE OUTCOME LIST
========================================================================== */
function createOutComeList(outcomeId, marketType, isAlreadyPlacedInOpenBet){
    return  {
        'id': outcomeId,
        'MarketType': marketType,
        'IsAlreadyPlacedInOpenBet': String(isAlreadyPlacedInOpenBet),
    }
}

function checkIfBetExist(type, bets){
    for (let index in bets) {
        if ((bets[index].type) == type) return true;
    }

    return false;
}

function createSelectionToPlaceBet(
    outcomeId,
    sportId,
    lineMarketId,
    marketId,
    gameId,
    lineTournamentId,
    marketType,
    odd,
    points,
    isLive,
    isAlreadyPlacedInOpenBet)
{
    return {
        "outComeId": outcomeId,
        "sportId": sportId,
        "lineMarketId": lineMarketId,
        "marketId": marketId,
        "gameId": gameId,
        "lineTournamentId": lineTournamentId,
        "marketType": marketType,
        "odd": odd,
        "points": points,
        "originalPoints": 0,
        "pointsPurchased": 0,
        "isLive": String(isLive),
        "pitcher": 0,
        "pointsPurchasedJuice": 0,
        "isAlreadyPlacedInOpenBet": String(isAlreadyPlacedInOpenBet)
    };
}

/*CHECK IF THE LINE ALREADY EXIST ON THE SELECTION OBJECT - BET SLIP
========================================================================== */
function containsObjectToCallBetSlip(object, key) {
    for (let s of object) {
        if ((s.id) === key) return true;
    }

    return false;
}

function containsObject(object, key) {
    for (let s of object) {
        if ((s.outcomeId) === key) return true;
    }

    return false;
}

/*CREATE SELECTION TO CALL BET SLIP
========================================================================== */
function createBetToCallBetSlip(type, wagerType, openSpots, wagerCount) {

    if(!checkIfBetExist(type, _betsToCallBetSlip)){
        const bet =  {
            "type": type,
            "wagerType": wagerType,
            "openSpots": 0,
            "wagerDetailCount": wagerCount,
        };
        _betsToCallBetSlip.push(bet);
    }
}

// function getAttr2Slip(target){
//     add2Slip(target.attr('id'),
//         target.data('gameid'),
//         target.data('rotation-num'),
//         target.data('sport-id'),
//         target.data('wager-type'),
//         target.data('spread'),
//         target.data('odds'),
//         target.data('description'),
//         target.data('team'),
//         target.data('section'),
//         target.data('team-vs')
//     );
// }

function setCurrenTabId(tab){
    if(tab == 'straight') _current_tab_id = 0;
    if(tab == 'parlay') _current_tab_id = 1;
    if(tab == 'teaser') _current_tab_id = 2;
    if(tab == 'if_win') _current_tab_id = 3;
    if(tab == 'round_robin') _current_tab_id = 5;
}


function add2Slip(target) {

    console.log('fafasdfadfasfafasdf412123414124346');

    const newSelectionToCallBetSlip = createOutComeList(
        target.data('outcomeid'),
        target.data('markettype'),
        "false"
    );

    const newSelection = createSelectionToPlaceBet(
        target.data('outcomeid'),
        target.data('sportid'),
        target.data('linemarketid'),
        target.data('marketid'),
        target.data('gameid'),
        target.data('linetournamentid'),
        target.data('markettype'),
        target.data('odd'),
        target.data('points'),
        target.data('isLive'),
        "false"
    );

    if (!containsObject(_selections, target.data('outcomeid')) && !containsObjectToCallBetSlip(_selectionsToCallBetSlip, target.data('outcomeid'))){

        try{
            _selectionsToCallBetSlip.push(newSelectionToCallBetSlip);
            _selections.push(newSelection);
            setListLineSports();
            createBetToCallBetSlip(_current_tab, _current_tab_id, 0, _selectionsToCallBetSlip.length);
            createOrUpdateBet(_current_tab, target.data('outcomeid'), _current_tab_id);
        } catch (error) {
            throw error;
        }

        if (_current_tab === 'straight' && _selectionsToCallBetSlip.length > 1) {
            try {
                createBetToCallBetSlip('parlay', 1, 0, _selectionsToCallBetSlip.length);
                createBetToCallBetSlip('teaser', 2, 0, _selectionsToCallBetSlip.length);
                createOrUpdateBet('parlay', '' , 1);
                createOrUpdateBet('teaser', '', 2);
            } catch (error) {
                return false;
            }
        }
    } else {
        removeFromSlip(target.data('outcomeId'));
        setListLineSports();
    }

    // showSlip();
}

function setListLineSports(){
    _sport_ids = [];
    _selections.forEach(selections => {
        const obj = {"lineSportId": selections.sportId};
        _sport_ids.push(obj);
    });
}

function getBetIndex(type, bet_id) {
    for (let index in _bets) {
        if (type === 'straight') {
            // debug('getting bet index '+ store.state._bets[index].betid +' bet '+bet_id);

            if ((_bets[index].betId) === bet_id) return index;

        } else if ((type === _bets[index].type) ||
            ((type === 'if_win' && _bets[index].type === 'if_win_or_tie') ||
                (type === 'if_win_or_tie' && _bets[index].type === 'if_win'))) {

            return index;
        }
    }

    return false;
}

function cleanOneSelectionExtraPlays(bets) {
    let _temp_bets = bets;

    if (_selections.length == 1 && (_current_tab == 'straight')) { //Only leaves straight and removes other bettypes

        _temp_bets = JSON.parse('[]');

        for (let i = 0; i < _bets.length; i++) {
            if (_bets[i].betid != '') _temp_bets.push(_bets[i]);
        }
    }

    return _temp_bets;
}

function removeFromSlip(line_id) {
    for (let i = 0; i < _selectionsToCallBetSlip.length; i++) {
        if ((_selectionsToCallBetSlip[i].id) == line_id)
            _selectionsToCallBetSlip.splice(i, 1);
    }

    for (let i = 0; i < _selections.length; i++) {
        if ((_selections[i].id) == line_id)
            _selections.splice(i, 1);
    }

    for (let i = 0; i < _bets.length; i++) {
        if ((_bets[i].betid) == line_id)
            _bets.splice(i, 1);
    }

    _bets = cleanOneSelectionExtraPlays(_bets); //removes extra plays in straight tab  like parlay and teaser

    if (_selections.length == 0) clearBets();

    saveCookie();
}

function removeSelection(bet_id) {
    jQuery("#" + bet_id).removeClass('active');
    removeFromSlip(bet_id);
    // showSlip();
}

/*CREATE OR UPDATE BET
========================================================================== */
function createOrUpdateBet(type, betId, wagerTypeId, amount ) {
    console.log('hp;laaaaa32412');
    let free_wager_prize_id = null, selected_sub_type = null;
    let bet_index = getBetIndex(type, betId);

    if (bet_index === false) {
        let new_bet = {
            'betId': betId,
            "type": type,
            "amount": 0,
            "wagerType": 0,
            "idWagerType": 0, // get this form bet slip response
            "roundRobinCombinations": 0, //Send it as round-robin combinations count , must be a number
            "riskWin": 2,
            "openSpots": 0,
            "FillIdWager": null,
            "FreeWagerPrizeId": null
        }

        _bets.push(new_bet);
    }
    else {
        try {

            selected_sub_type = document.querySelector('select.teaser-select')

            if (selected_sub_type != null) {
                selected_sub_type = (sub_type == null && type === 'teaser') ? 0 : selected_sub_type.value;
            }

            if (amount >= 0) {
                _bets[bet_index].amount = amount;
            }

        } catch (e) {
            console.error(e);
            return false;
        }
    }

    saveCookie();
}

function getWin(type, line_id, odds, maxpayout, maxpayoutodd, option) {
    const open_spots        = getOpenSpotsCount();
    const risk_input_amt    = jQuery('#risk_' + line_id).val();
    const current_win       = jQuery('#win_' + line_id);
    let win_input_amt       = '';
    let temp_amount         = 0;
    let totals              = "";

    if (risk_input_amt > 0) {
        if (type == 'straight') {
            for (let i = 0; i < _selections.length; i++) {
                if (_selections[i].id == line_id) temp_amount = riskToWin(risk_input_amt, _selections[i].new_odds);
            }
        } else if (type == 'parlay') {
            temp_amount = riskToWin(risk_input_amt, parlayGetOdd(_selections, odds, maxpayoutodd));
        } else if (type == 'teaser') {
            let teaser_odds = 0;

            if (_current_tab == 'teaser') {
                teaser_odds = jQuery('.tab-pane#teaser select.teaser-select').find(':selected').attr('data-odds');
            } else {
                teaser_odds = jQuery('.tab-pane#straight select.teaser-select').find(':selected').attr('data-odds');
            }

            temp_amount = riskToWin(risk_input_amt, teaser_odds);
        }
        else if (type == 'round_robin') {
            let round_robin_type    = jQuery('#rr-select').find(':selected').val();
            let parlay_table        = jQuery('#risk_round_robin_1').data('parlay-table');
            totals                  = roundRobinGetAllOdds(_selections, parlay_table, round_robin_type);
            temp_amount             = riskToWin(risk_input_amt, totals.winFactor );

        }else if (type == 'if_win') {
            totals                  = ifWinGetAllOdds(_selections);
            temp_amount             = riskToWin(risk_input_amt, totals.winFactor);
        }else if (type == 'reverse') {
            totals                  = reverseGetAllOdds(_selections);
            temp_amount             = riskToWin(risk_input_amt, totals.winFactor);
        }
    }

    let tempmaxpayout = maxpayout;

    if (temp_amount > tempmaxpayout && tempmaxpayout > 0) temp_amount = tempmaxpayout;

    if (!newVersion){
        current_win.val(temp_amount);
        win_input_amt =  current_win.val();
    }else{
        current_win.html(temp_amount);
        win_input_amt =  current_win.text();
    }

    // createOrUpdateBet(type, line_id, risk_input_amt, win_input_amt, open_spots, option);
    totalBets(totals);
}

// function getRisk(type, line_id, odds, maxpayout, option) {
//     const open_spots = getOpenSpotsCount();
//     const win_input_amount = jQuery('#win_' + line_id).val();
//     const current_risk = jQuery('#risk_' + line_id);
//     let risk_input_amt = '';
//     let temp_amount = 0;
//
//     if (win_input_amount > 0) {
//         if (win_input_amount > 0) {
//             if (type == 'straight') {
//                 for (let i = 0; i < _selections.length; i++) {
//                     if (_selections[i].id == line_id) temp_amount = winToRisk(win_input_amount, _selections[i].new_odds);
//                 }
//             } else if (type == 'parlay') {
//                 temp_amount = winToRisk(win_input_amount, parlayGetOdd(_selections, odds));
//             } else if (type == 'teaser') {
//                 let teaser_odds = 0;
//
//                 if (_current_tab == 'teaser') {
//                     teaser_odds = jQuery('.tab-pane#teaser select.teaser-select').find(':selected').attr('data-odds');
//                 } else {
//                     teaser_odds = jQuery('.tab-pane#straight select.teaser-select').find(':selected').attr('data-odds');
//                 }
//
//                 temp_amount = winToRisk(win_input_amount, teaser_odds);
//             }
//         }
//
//         current_risk.val(temp_amount);
//         risk_input_amt = current_risk.val();
//
//     }
//     createOrUpdateBet(type, line_id, risk_input_amt, win_input_amount, open_spots, option);
// }

function updateVisualLine(type, selection, lineId) {
    let pts     = parseFloat(selection.new_spread).toFixed(1);
    let odds    = this.oddVisual(parseInt(selection.new_odds));
    // this.debug({'Update visual line with parameters:': {'type':type,'selection':selection,'lineId':lineId}});

    if(pts == 0){
        if(selection.dgs_sport_id == 'TNT'|| selection.line == 'ml'){
            pts = '';
        }else{
            pts = 'PK';
        }
    }else{
        if(pts > 0){
            if(selection.line != 'to') {
                pts = '+'+pts.replace('.0','');
            }
            else{
                pts = 'U '+pts.replace('.0','');
            }
        }
        if(pts < 0){
            if(selection.line != 'to') {
                pts = pts.replace('.0','');
            }
            else{
                let point = pts.replace('-','');
                pts = 'O '+point.replace('.0','');
            }
        }
    }

    this.changeHTML("[data-selection-identifier-points='"+lineId+"']" ,pts);
    this.changeHTML("[data-selection-identifier-odds='"+lineId+"']" ,odds);
}

function updatePointsSelection(selection_id, spread, odds, points) {
    let  selection_index = getSelectionIndex(selection_id);
    // debug({
    //     'update points selection with:': {
    //         'selection_id': selection_id,
    //         'spread': spread,
    //         'odds': odds,
    //         'points': points
    //     }
    // });

    if (selection_index != null) {
        _selections[selection_index].new_odds = odds;
        _selections[selection_index].new_spread = spread;
        _selections[selection_index].points = points;
        updateVisualLine('straight', _selections[selection_index], selection_id);
        updateVisualLine('parlay', _selections[selection_index], selection_id);
    } else {
        // debug('selection was not found');
    }
}

function updatePitcherSelection(pitcher_selection, selection_id) {
    let selection_index = getSelectionIndex(selection_id);
    if (selection_index != null) _selections[selection_index].pitcher_selection = pitcher_selection;
    // else debug('selection was not found');
}

function getSelectionIndex(selection_id) {
    for ( let i = 0; i < _selections.length; i++) {
        if ((_selections[i].id) == selection_id) return i;
    }

    return null;
}

function updateSubType(bet_type, sub_type) {
    for ( let i = 0; i < _bets.length; i++) {
        const bet = _bets[i];
        if (bet.type == bet_type) {
            // debug('Set sub_type to ' + sub_type + ' for bet_type ' + bet_type);
            _bets[i].sub_type = sub_type;
        }
    }
}

function updateOpenSpots(bet_type, open_spots) {
    for ( let i = 0; i < _bets.length; i++) {
        const bet = _bets[i];
        if (bet.type == bet_type) {
            _bets[i].open_spots = open_spots;
            saveCookie();
        }
    }
}

function updateIfBetType(bet_type) {
    for (let i = 0; i < _bets.length; i++) {
        const bet = _bets[i];
        if (isIfBet(bet.type)) {
            // debug('Set type to ' + bet_type + ' for bet_type ' + bet_type);
            _bets[i].type = bet_type;
            _current_tab = bet_type;
        }
    }
}

function isIfBet(bet_type) {
    return bet_type == "if_win" || bet_type == "if_win_or_tie";
}

function getOpenSpotsCount() {
    const combo = jQuery('.open-spot-picker');

    if (combo.length > 0)
        return combo.val();

    return 0;
}

function triggerRiskWinCalculation() {
    jQuery('.risk_input').filter(function () {
        return parseInt(jQuery(this).val()) > 0;
    }).trigger("keyup");
}

function oddVisual(odd) {
    let odds = parseInt(odd);
    if (_lineformat == 'D') {
        odds = parseFloat(decimalFromUS(odds)).toFixed(2);
    } else if (_lineformat == 'F') {
        odds = fractionFromUS(odds);
    } else {
        if (odds == 0 || odds == 100) {
            odds = 'EV';
        } else if (odds > 0) {
            odds = '+' + odds + '';
        } else {
            odds = '' + odds + '';
        }
    }

    return odds;
}

//UPDATE TOTAL BETS, TOTAL BET AMOUNT AND TOTAL WIN AMOUNT
function totalBets(totals) {
    let count               = 0;
    let totalRisk           = 0;
    let potentialWin        = 0, win = 0;
    const place_bet_btn     = jQuery('.betslip__btn-betslip-place');
    const totalBets         = jQuery('#' + _current_tab + ' .total-bets');
    const totalRiskDetail   = jQuery('#' + _current_tab + ' .total-risk');
    const totalWinDetail    = jQuery('#' + _current_tab + ' .total-win');

    if (jQuery('#' + _current_tab).hasClass('active') && _current_tab != "if_win") {
        jQuery('#' + _current_tab + ' .risk_input').each((index, self) => {
            const amount = parseFloat(jQuery(self).val());
            if (amount >= 0) {
                count = count + 1;
                totalRisk = amount + totalRisk;
            }
        });

        jQuery('#' + _current_tab + ' .win_input').each((index, self) => {
            if(!newVersion)
                win = parseFloat(jQuery(self).val());
            else
                win = parseFloat(jQuery(self).text());

            if (win >= 0)
                potentialWin = win + potentialWin;
        });

        if(totals != "") {
            let totalRiskResult = totalRisk * totals.riskFactor;
            totalBets.val(totals.betCount != "" ? totals.betCount : "0");
            totalRiskDetail.val('$' +totalRiskResult.toFixed(2));
        }else{
            totalBets.val(count);
            totalRiskDetail.val('$' +totalRisk.toFixed(2));
        }

        totalWinDetail.val('$' + potentialWin.toFixed(2));
    }
    if (jQuery('#if_win').hasClass('active')) {
        if (_current_tab == "if_win_or_tie" || _current_tab == "if_win") {
            jQuery('#if_win .risk_input').each((index, self) => {
                const amount = parseFloat(jQuery(self).val());
                if (amount >= 0) {
                    count = count + 1;
                    totalRisk = amount + totalRisk;
                }
            });
            jQuery('#if_win .win_input').each((index, self) => {
                if(!newVersion)
                    win = parseFloat(jQuery(self).val());
                else
                    win = parseFloat(jQuery(self).text());

                if (win >= 0)
                    potentialWin = win + potentialWin;
            });

            jQuery('#if_win .total-bets').val(count);
            jQuery('#if_win .total-risk').val('$' + totalRisk.toFixed(2));
            jQuery('#if_win .total-win').val('$' + potentialWin.toFixed(2));
        }
    }

    totalRisk > 0 ? place_bet_btn.removeClass('disabled') : place_bet_btn.addClass('disabled');
    totalRisk > 0 ? place_bet_btn.prop('disabled', 0) : place_bet_btn.prop('disabled', 1);
}

//CURRENT TAB
function highlighteCurrentTab() {
    if (_current_tab == "straight") {
        jQuery('#straight-tab').addClass('active');
    }
    if (_current_tab == "parlay") {
        jQuery('#parlay-tab').addClass('active');
    }
    if (_current_tab == "teaser") {
        jQuery('#teaser-tab').addClass('active');
    }
    if (_current_tab == "round_robin") {
        jQuery('#rr-tab').addClass('active');
    }
    if (_current_tab == "if_win" || _current_tab == "if_win_or_tie") {
        jQuery('#if_bet-tab').addClass('active');
    }
    if (_current_tab == "reverse") {
        jQuery('#reverse-tab').addClass('active');
    }
}

function countSelections() {
    const counter =  _selections.length;
    const bs_badge_counter = jQuery('.badge-counter');
    if(counter > 0){
        bs_badge_counter.removeClass('d-none').html(`(${counter})`);
    }else if (counter > -1) {
        bs_badge_counter.addClass('d-none').html('');
    }
}

function checkSize() {
    const currentSize = window.matchMedia("(max-width: 640px)");

    return currentSize.matches;
}

function fixBetSlipOnInit(){
    if(checkSize()){
        const body      = jQuery('body');
        const betSlip   = jQuery('#bet-slip-main');

        // if(!body.hasClass('modal-open freezen-modal')){
        if(betSlip.hasClass('show')){
            const betDetailsCta = jQuery('.cta-row');
            body.width(body.width());
            body.css('overflow', 'hidden');
            betDetailsCta.addClass('position-fixed');
        }
        // }
    }
}

function fixBetSlipMobile (body, chat){
    const betDetailsCta = jQuery('.cta-row');
    if(checkSize()){
        body.width(body.width());
        body.css('overflow', 'hidden');
        chat.css('bottom', '2rem');
        betDetailsCta.addClass('position-fixed');
    }
}

function betSlipStatus (betSlip){
    const status = parseInt(getItemFromLocalStorage('bs_status'));

    if (isNaN(status)) return;

    if(status)
        betSlip.addClass('show');
    else
        betSlip.removeClass('show');
}

function collapseBetSlipMobile(){
    const betSlip           = jQuery('#bet-slip-main');
    const body              = jQuery('body');
    const chat              = jQuery('.contact-help-chat');

    betSlipStatus(betSlip);

    betSlip.on('show.bs.collapse',  () => {
        saveOnLocalStorage('bs_status', '1');
        fixBetSlipMobile(body, chat);
    });

    betSlip.on('hide.bs.collapse',  () => {
        saveOnLocalStorage('bs_status', '0');
        const betDetailsCta     = jQuery('.cta-row');

        if(checkSize()) {
            body.removeAttr("style");
            chat.css('bottom', '3.3rem');
            betDetailsCta.removeClass('position-fixed');
        }
    });
}
